.navbar{
    &_container{
        margin-bottom: 10px;
        z-index: 10;
        top: 0;
        width: 100vw;
        background-color: #121832;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 2px 5px 0px #000000;
        cursor: pointer;
        img{
            margin-left: 25px;
            width: 170px;
        }
        h4{
            color: white !important;            
        }
    }
    &_profile{
        display: flex;
        margin-right: 30px;
    }
}