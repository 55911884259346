.sow_planification_view_section{
    &_container{
        position: relative;
        display: flex;
        flex-direction: column;
      //  overflow: hidden;
        height: 100%;   
        width: 100%;   
        h2{
          margin-left: 15px ;
        }       
    } 
    .ant-table-cell{
        cursor: pointer;
        font-size: 12px !important;
        text-align: center !important;
        padding: 0px !important;
        margin: 0px;
    }
    .ant-table-wrapper .ant-table-filter-trigger{
      margin-inline: 0px !important;
    }
    .ant-table-thead{
      font-size: 13px !important;
    } 
    
    
}

.sizeCell_FS_h{
  margin: 0px;
  padding: 0px;
  height: 18.84px !important;
  .div{
    height: 18.84px !important;
  }
 
}
.ant-form-item .ant-form-item-control-input{
  height: 18.84px !important;
  min-height: 18.84px!important;
}

.ant-table-header{
  max-height: 40px !important;    
}

.img{
  height: 5px ;
}

.cell_backround_color{
  background-color: #e7e7e7;// #00b30334;
  width: 100%;
  height: 18.84px;
}

.sentinelGraph {
  &_chartContainers {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20px;
  }
}

.recharts-legend-wrapper{
  position: relative !important;
  margin-top: 40px;
}

.pp{
  color: rgb(180, 180, 180)
}
