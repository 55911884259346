.startupBackground{
    height: 100%;
    &-containerImg{
        background-image: url('../../assets/images/background/background2.jpg');
        background-size: cover;
        background-position: center;
        height: 100vh; 
        background-attachment: fixed;
    }
    &-opacityLayer{
        position: absolute;
        height: 100%;   
        width: 100vw;  
        background: linear-gradient(to bottom right, rgba(1, 1, 49, 0.25), rgba(7, 0, 41, 0.25));
        //background-color: rgb(0, 0, 0, 0.5); 
        z-index: -100;
    }
    &-containerImg{
        position: absolute;
        z-index: -500;
        height: 100%;
        width: 100vw;
    }
}

@media only screen and (max-width: 414px){
    .startupBackground{
        &-opacityLayer{
            height: 100%;   
            width: 100vw;  
        }
    }
}

@media only screen and (max-width: 915px) and (orientation: landscape) {
    .startupBackground{
 
        &-opacityLayer{
            height: 700px;    
        } 
    }
}

