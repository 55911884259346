.ant-menu-dark.ant-menu-root.ant-menu-inline{
    overflow-y: auto;
    /* Para navegadores que no sean WebKit (como Firefox) */
    scrollbar-width:thin; /* Ancho del scrollbar */
    scrollbar-color: #6b6f92 #121832 !important; /* Color del scrollbar y del fondo del track */   
}

/* Estilo del scroll para WebKit (Chrome, Safari) */
.ant-menu-dark.ant-menu-root.ant-menu-inline::-webkit-scrollbar {
    width: 8px; /* Ancho del scrollbar */
}
  
.ant-menu-dark.ant-menu-root.ant-menu-inline::-webkit-scrollbar-track {
    background-color: #121832; /* Color del fondo del track */
}

.ant-menu-dark.ant-menu-root.ant-menu-inline::-webkit-scrollbar-thumb {
    background-color: #6b6f92 !important; /* Color del scrollbar */
    border-radius: 4px; /* Redondear las esquinas */
}

.icon_deliteOutlined{
    color: #03dfdf !important;
    &:hover{
        color: rgb(0, 114, 206) !important
    }
}

.icon_editOutlined{
    color: #03dfdf !important;
    &:hover{
        color: #0072ce !important
    }
}


.ant-btn-primary:disabled{
    background-color: rgb(209, 209, 209) !important;
}

h4{
    color: #121832 !important;
}

h2{
    color: #121832 !important;
    margin: 10px;
}

.ant-btn-primary{
    background-color: #0072ce !important;
    span{
        color: white !important;
    }
    &:hover{
        background-color: #018dff !important;
    }
}

.bodyContent_container_table{
    display:flex;
    justify-content: center;
    width: 100%;
    height: 70%,
}

.bodyContainer_title{
    display: flex; 
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #0072ce;
    font-size: 12px;
}

.icons{
    color: #6495ed;
    &:hover{
        color: #8ab1fa// rgb(138, 179, 255);
    }
}
.ñ{
    color: #dfdfdf
}

.box_filter{
    color: white;
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    height: 100%;
    p{
        font-size: 16px;
    }
    span{
        color:  #75799c;
    }
    &_group{
        display: flex;
        flex-direction: column;
        margin-top: 15px;
    }
}


.filter_scroll{
    overflow-y: scroll;
}
.filter_scroll::-webkit-scrollbar {
    width: 5px;
}
.filter_scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.5); 
    border-radius: 10px;
}
.filter_scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 3px #6495ed;
}

.recharts-tooltip-label{
    color: black
}

.ant-checkbox-checked .ant-checkbox-inner{
    background-color: #1677ffad
}

.cursor_pointer{
    cursor: pointer;
}

.ant-table-cell-row-hover{
    background-color: #e0e0e0 !important; //hover de cursor sobre fila
}

.ant-table-wrapper .ant-table-filter-trigger{
    margin-inline: 0px !important;
}
  

.header_row{
    width: 100%;
    padding-left: 10px;
    margin: 10px 0px;
}
