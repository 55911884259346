.filters{
    &_container{
        position: absolute;
        right: 0px;
        bottom: 0px;
        z-index: 300;
        transition: transform 0.3s ease; // Agregamos una transición para el desplazamiento suave
    }
}

.collapsed{
    transform: translateX(calc(100% - 66px)); // Desplaza el contenedor completamente hacia la izquierda
}