.bag_distribution_section{
    &_container{
        position: relative;
        display: flex;
        flex-direction: column;
      //  overflow: hidden;
        height: 100%;   
        width: 100%;   
        h2, h4{
            color: white;
        }    
        td.ant-table-cell{
          //font-size: 12px !important;
          padding: 0px 0px 0px 15px!important;
          margin: 0px;
          height: 20px !important;
          font-size: 12px;
        }
        th.ant-table-cell{
          padding: 5px 5px 5px 15px !important;
          margin: 0px !important;
        }
        p{
          margin: 0px;
          font-size: 12px;
        }  
        .ant-table-thead{
          font-size: 13px !important;
        } 
    } 
   

    &_col{
        display: flex;

      .h3_title{
        margin-right: 5px;
      }
       
      h3{
        margin-right: 15px;
      }   
      .h3{
        color: blue
      }         
    }
}

.box_filter{
  .ant-checkbox-group{
    display: flex !important;
    flex-direction: column !important;    
  }
}

.ant-table-summary .ant-table-cell{
  background-color: rgb(150, 150, 150) !important;
  color: white;
}

.ant-table-header{
  max-height: 55px !important;    
}


