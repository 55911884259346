.GDUsHtorical_section{
    &_container{
        position: relative;
        display: flex;
        flex-direction: column;
      //  overflow: hidden;
        height: 100%;   
        width: 100%;   
        h2, h4{
            color: white;
        }       
    } 
    &_no-data{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        img{
            height: 300px;            
        }
        h3{
            color: white;
            margin-bottom: 5px;
        }
        p{
            color: orange;
            margin: 0px;
            font-size: 14px;
        }
    }    
}

.row_scroll{
    overflow-y: scroll;
}
.row_scroll::-webkit-scrollbar {
    width: 10px;
}
.row_scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    border-radius: 10px;
}
.row_scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #6495ed;
}

