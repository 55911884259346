.bag_order_section{
    &_container{
        position: relative;
        display: flex;
        flex-direction: column;
      //  overflow: hidden;
        height: 100%;   
        width: 100%;   
        h2, h4{
            color: white;
        }    
        
        td.ant-table-cell{
          //font-size: 12px !important;
          padding: 0px 0px 0px 15px!important;
          margin: 0px;
          height: 30px !important;
        }
        th.ant-table-cell{
          padding: 5px 5px 5px 15px !important;
          margin: 0px !important;
        }
        p{
          margin: 0px;
          font-size: 12px;
        }
    } 

    &_col{
      display: flex;

    .h3_title{
      margin-right: 5px;
    }
     
    .h3{
      color: blue;
      margin-right: 30px;
    }            
  }       
}


