.dashboard{
    &_container{
        background: linear-gradient(#e5e5e5 0%, #fff 25%) ;
        height: 100vh;
    }
    &_body{
        width: 100%;
        height: calc(100% - 80px);
        display: flex;
        flex-direction: row;
    }
}
